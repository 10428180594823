@import '/src/assets/styles/variables.scss';

.app-data-modal{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease;
    opacity: 0;
    pointer-events: none;
    .modal-bg{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $black-faint2;       
    }
    .modal-container{
        width: 90%;
        max-width: 700px;
        position: relative;
        padding: 10px;
        border-radius: 15px;
        background-color: $white;
        transition: 0.4s ease;
        transform: scale(0.001);
        .modal-closer{
            position: absolute;
            top: - 40px;
            right: -10px;
            border-radius: 25%;
            opacity: 0.9;
            background-color: $red;
            color: $white;
            padding: 3px 10px;
            transition: 0.2s ease;
            cursor: pointer;
            @include mediaM(500px) {
                padding: 4px 8px 3px;
            }
            &:hover{
                background-color: $red-hover;
                opacity: 1;
            }
            &:active{
                transform: scale(1.1);
                background-color: $red-active;
            }
        }
        .modal-content{
            position: relative;
            width: 100%;
            max-height: 80vh;
            overflow: auto;
            border: none;
        }
    }
    .wide{
        max-width: 1000px;
    }
    .small{
        max-width: 450px;
    }
  }